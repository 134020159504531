/*
 * Copyright (C) 2005 - 2022 TIBCO Software Inc. All rights reserved. Confidentiality & Proprietary.
 * Licensed pursuant to commercial TIBCO End User License Agreement.
 */

import 'jrs-ui/src/util/webpackPublicPathSetup';
const importStartup = import('jrs-ui/src/util/mainPagesStartup')
const importMain = () => import('jrs-ui/src/login/login.common');
const importCommonModule = () => import('jrs-ui/src/commons/commons.minimal.main')

importStartup.then(({default: startup}) => startup({
    importCommonModule,
    bundles: [
        "jasperserver_config",
        "jasperserver_messages",
        "jsexceptions_messages"
    ]
})).then(importMain)